import React from 'react';
 
 const TrackingCrmScript = () => {
   
   return (
     <>
       <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/7026579.js"></script> 
     </>
   );
 };
 
 export default TrackingCrmScript;