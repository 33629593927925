import React, { createContext, useState, useEffect } from "react";

export const LoadingContext = createContext();

const LoadingProvider = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", () => setWindowWidth(window.innerWidth));
    return () => {
      window.removeEventListener("resize", () =>
        setWindowWidth(window.innerWidth)
      );
    };
  }, []);

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading, windowWidth }}>
      {props.children}
    </LoadingContext.Provider>
  );
};

export default LoadingProvider;
