import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import i18n from "i18next";

const PrivacyPolicy = ({ setOpenPrivacyPolicy }) => {
  const [language, setLanguage] = useState("en");

  useEffect(() => {
    if (i18n.language === "en") {
      setLanguage("en");
    } else {
      setLanguage("es");
    }
  }, [i18n.language]);

  if (language === "en") {
    return (
      <div className="privacy__container">
        <div className="privacy__content">
          <div className="privacy__close-container">
            <span
              className="privacy__span-close text-blue_dark"
              onClick={() => setOpenPrivacyPolicy(false)}
            ></span>
          </div>
          <p className="privacy__title">
            DATA HANDLING, PROTECTION AND PRIVACY PROCEDURE
          </p>
          <div className="privacy__text">
            <br />
            <p>
              EXSIS DIGITAL ANGELS S.A.S. USERS’ DATA HANDLING,
              PROTECTION AND PRIVACY POLICY Due to the commercial acivity
              executed by EXSIS Digital Angels in compliance with Decree
              1377 from year 2013, the data handling, protection and privacy
              policy that Exsis Digital Angels implements is
              established.
            </p>
            <br />
            <p className="privacy__subtitle">I. IDENTIFICATION</p>
            <br />
            <p>
              COMPANY NAME: EXSIS DIGITAL ANGELS S.A.S. (hereinafter
              EXSIS), company with pure colombian capital and funds, which
              belongs to the Colombian common regime and dedicated to offering
              software solutions that allow to meet needs and expectations when
              handling customers’ data handling, utilizing the lastest
              technology and advances guaranteeing quality both in products
              creation and services.
            </p>
            <br />
            <p>
              DOMICILE: Bogota.
              <br />
              ADDRESS: Street 145 # 48-16, Bogota D.C.
              <br />
              E-MAIL: habeasdata@exsis.com.co
              <br />
              PERSON IN CHARGE, CONTACT NUMBER: 4846750 Ext. 1021
              <br />
              <br />
              <span className="privacy__subtitle">II. LEGAL FRAMEWORK</span>
              <br />
              <br />
              Political constitution, 15th article.
              <br />
              The 1266 Act from year 2008
              <br />
              The 1581 Act from year 2012
              <br />
              1727 and 2952 Regulatory decrees from years 2009 and 2010
              <br />
              1377 Partial Regulatory decree from year 2013
              <br />
            </p>
            <br />
            <p className="privacy__subtitle">III. DEFINITIONS</p>
            <br />
            <p>
              <span className="privacy__subtitle">AUTHORIZATION: </span>The
              holder’s previous, explicit and informed approval or permission to
              carry out the data handling its protection and privacy.
              <br /> <br />
              <span className="privacy__subtitle">PRIVACY NOTICE: </span>verbal
              or written communication generated by the temporary responsible
              person directed towards the holder for data handling, protection
              and privacy, by which the existence of applicable data handling,
              protection and privacy policies are informed, how to access these
              policies and the purposes of data handling, protection and privacy
              that is intended for personal data privacy.
              <br /> <br />
              <span className="privacy__subtitle">DATABASE: </span>dataset that
              is composed of personal data subject to data handling.
              <br />
              <br />
              <span className="privacy__subtitle">SUCCESSOR: </span>a person who
              takes over the rights of another because of death or inheritance
              (heir).
              <br />
              <br />
              <span className="privacy__subtitle">PERSONAL DATA: </span>any
              piece of information involved with one person or several
              determined individuals or determinable people able to join a
              natural or juridical person. <br />
              <br />
              <span className="privacy__subtitle">PUBLIC DATA: </span>it is the
              data that is not semi-private, private or sensitive. Public data
              is considered to be, amongst others, data related to people’s
              marital status, profession or job, quality as merchant or public
              servant. By nature, public data can be contained, amongst others,
              in public records or documents, official legal bulletins, gazetes
              and legal decisions properly executed that are not subject to
              legal reserve. <br />
              <br />
              <span className="privacy__subtitle">SENSITIVE DATA: </span>is any
              data that affects the holder’s intimacy, political orientation,
              religious or philosophical beliefs, participation in trade unions,
              social, human rights, or organizations that support political
              parties’ interests, or those who guarantee rights and political
              opposition, as well as, those related to health, sexual life and
              biometric data. <br />
              <br />
              <span className="privacy__subtitle">MANDATORY DATA: </span>It is
              understood as the holders’ personal mandatory data to continue
              with the company’s operation. The mandatory in essence data should
              be provided by the data holders or legitimate ones, who can exert
              these rights.
              <br />
              <br />
              <span className="privacy__subtitle">
                TEMPORARY RESPONSIBLE PERSON FOR DATA PRIVACY:
              </span>
              a natural, juridical, public or private person, who on her or his
              own or together with others, executes personal data handling on
              behalf of the responsible person for data privacy.
              <br />
              <br />
              <span className="privacy__subtitle">DATA PRIVACY ACT: </span>It is
              all the regulation involved with the 1581 act from year 2012 and
              its regulatory Decrees, regulations and ammendments that modify,
              complement or substitute regulations.
              <br />
              <br />
              <span className="privacy__subtitle">HABEAS DATA: </span>is the
              right that any person has to know, to update and to change
              information that has been gathered or collected in the databases
              and files from public and private entities.
              <br />
              <br />
              <span className="privacy__subtitle">
                RESPONSIBLE PERSON FOR DATA PRIVACY:
              </span>
              a natural or juridical, public or private person who on his or her
              own or together with others, decides about the database and/or
              about data handling or its privacy.
              <br />
              <br />
              <span className="privacy__subtitle">HOLDER: </span>is a natural
              person whose personal data is subject to data Handling.
              <br />
              <br />
              <span className="privacy__subtitle">DATA HANDLING: </span>are any
              operations or set of operations about personal data, such as
              collection, gathering, usage, distribution or deletion.
              <br />
              <br />
              <span className="privacy__subtitle">TRANSFERENCE: </span>Data
              transference occurs when the temporary or responsible person for
              personal data privacy, located in Colombia, submits data or
              personal data to a recipient, who is also responsible for personal
              data handling and located in Colombia or abroad.
              <br />
              <br />
              <span className="privacy__subtitle">TRANSMISSION: </span>Data
              handling or Data privacy that conveys communication of Data
              handling or Data privacy within Colombia’s national territory or
              abroad when the purpose is to have date managed by the temporary
              responsible person on behalf of the responsible person for data
              privacy.
              <br />
            </p>
            <br />
            <p className="privacy__subtitle">IV. PRINCIPLES</p>
            <br />
            <p>
              For the development, interpretation and law enforcement from the
              1581 act from year 2012 by which general dispositions are dictated
              for the personal data privacy and the regulations that complement,
              modify and change this act, the following governing principles
              will be applied in a harmonic and integral way:
            </p>
            <br />
            <p>
              <span className="privacy__subtitle">
                a) PRINCIPLE OF LEGALITY:
              </span>
              data privacy is a regulated activity that must stick to what is
              established in the law and other dispositions related to it.
              <br />
              <br />
              <span className="privacy__subtitle">
                b) PRINCIPLE OF PURPOSE:
              </span>
              the data privacy must follow a legitimate purpose in compliance
              with the Constitution and Laws, and must be notified to the
              holder. <br />
              <br />
              Regarding personal data collection, EXSIS will only collect data
              that is pertinent and reasonable for the purpose that it was meant
              to be collected or gathered. The holder needs to be notified of
              the reason why and purpose for which the information is being
              required and the specific usage that this information will have.
              <br />
              <br />
              <span className="privacy__subtitle">
                c) PRINCIPLE OF FREEDOM:
              </span>
              Privacy data and its handling can only be exerted with previous,
              expressed and informed approval or permission from the holder.
              Personal data will not be obtained or disclosed without previous
              authorization, or in absence of legal or judicial order affecting
              the permission. <br />
              <br />
              <span className="privacy__subtitle">
                d) PRINCIPLE OF VERACITY OR QUALITY:
              </span>
              data subject to privacy and data handling must be reliable,
              complete, exact, updated, verifiable and understandable.
              Incomplete, parcial, fragmented or misleading data handling is
              prohibited. <br />
              <br />
              <span className="privacy__subtitle">
                e) PRINCIPLE OF TRANSPARENCY:
              </span>
              with data handling the holder’s right must be guaranteed to obtain
              information about the existence of data that he or she is
              interested in, in any moment and with no restraints by the person
              who is responsible for data privacy or the temporary person in
              charge.
              <br />
              <br />
              <span className="privacy__subtitle">
                f) PRINCIPLE OF ACCESS AND RESTRICTED CIRCULATION:
              </span>
              the data handling sticks to the limits that derive from the nature
              of personal data, Law dispositions and the Constitution. In this
              way, data handling can only be executed by individuals who are
              authorized by the holder or individuals allowed by Law. Personal
              data, except for public data, will not be available on the
              internet nor any other means of advertisement or massive
              communication, unless the access is technically controlled to
              offer a restricted access of information only for the holders or a
              third person authorized by law. <br />
              <br />
              <span className="privacy__subtitle">
                g) PRINCIPLE OF SAFETY:
              </span>
              data subject to EXSIS handling, needs to be handled with the
              technical, human and administrative measures that are necessary to
              provide security to the records preventing adulteration, loss,
              consultation, use or unauthorized or fraudulent use or access from
              happening. <br />
              <br />
              <span className="privacy__subtitle">
                h) PRINCIPLE OF CONFIDENTIALITY:
              </span>
              EXSIS is obligued to guarantee the reserve of data, even after
              finishing its relation with any of the tasks that handling
              requires, being only able to supply or communicate personal data
              when it corresponds to the development of legal activities.
              <br />
            </p>
            <br />
            <p className="privacy__subtitle">V. RIGHTS</p>
            <br />
            <p>
              EXSIS is committed to protecting the privacy and guaranteeing the
              habeas data rights, so that customers are able to know, update and
              modify personal data handled by EXSIS. For this reason, in
              compliance with the 1581 act from year 2012 and the 1377 Decree
              from year 2013, it is stated how the holder counts on the below
              rights:
            </p>
            <br />
            <p>
              a) To know, to update and to modify his or her personal details
              before EXSIS or its managers, in its responsible for private data
              handling condition. This right can be exerted, amongst others,
              when Partial, inaccurate, incomplete, fragmented, misleading
              personal data or those prohibited or those ones that have not been
              authorized occur. <br />
              <br />
              b) To request proof of authorization granted to EXSIS, unless
              there exists a legal disposition that indicates the authorization
              is unnecessary or that it has been validated according to the 10th
              article of the 1377 Decree. <br />
              <br />
              c) To request petitions to EXSIS regarding the personal data use
              and to receive such information. <br />
              <br />
              d) To file a complaint before the superintendence of industry and
              commerce for infractions related to the 1581 act from year 2012
              and the 1377 Decree from year 2013. <br />
              <br />
              e) To revoke his or her authorization and/or to request personal
              data deletion from EXSIS databases, only if a legal duty does not
              exist (when the user has has bought an article or service there
              are legal and tax obligations to have a the record of purchase for
              the bought items, hence this information must remain on the EXSIS
              record of sales, this, with foundation on the 60th article C.Co)
              or a an EXSIS holder contractual type of obligation, under which
              the holder has no right to request his or her personal data
              deletion or to revoke his or her authorization for data handling.
              If there are no legal or contractual pending duties and EXSIS has
              not deleted the holder’s personal data from its database or has
              not revoked the authorization from whom is legitimate to do so
              within the legal terms, the holder will be able to go before the
              superintendence of Industry and Commerce to demand the revocation
              of authorization and/or the personal data deletion. <br />
              <br />
              f) To request and gain free access to his or her personal data
              that has been subject to data handling. <br />
              <br />
              These Policies guarantee that any provided piece of information
              will be kept private and secure. To certify this, we provide in
              this document the details of which data we obtained and the way we
              used it. We never gather data without previous, explicit and
              informed approval or permission, unless personal data was under
              handling process by EXSIS before the 1377 Decree from year 2013
              was issued and the procedure in the 10th article from that
              mentioned Decree has been implemented. This document is an
              integral part of EXSIS Terms and conditions. By the acceptance of
              these Terms and conditions you accept you have been informed about
              our Policy.
            </p>
            <br />
            <p className="privacy__subtitle">VI. DUTIES</p>
            <br />
            <p>
              according to the data handling and data protection policy, in this
              document, the duties for which EXSIS is responsible are the
              following ones, subject to legal dispositions contained in the
              law.
            </p>
            <br />
            <p>
              a) To guarantee the holder, at any time, the full and effective
              exercise of the habeas data right. <br />
              <br />
              b) To request and to keep the corresponding holder’s granted
              authorization copy. <br />
              <br />
              c) To inform accurately the holder about the data collection
              purpose and rights in favor due to the granted authorization.
              <br />
              <br />
              d) To keep the data under the necessary security conditions to
              prevent adulteration, loss, consultation, use or unauthorized or
              fraudulent access from happening.
              <br />
              <br />
              e) To guarantee that the data is realiable, complete, precise,
              updated, verifiable and understandable.
              <br />
              <br />
              f) To update data, taking care of the customers’ requests about
              the holder’s data. Additionally, all possible measures must be
              implemented, so that data remains up-to-date.
              <br />
              <br />
              g) To correct data when it is incorrect and to communicate it.
              <br />
              <br />
              h) To respect the holder’s data privacy and security conditions.
              <br />
              <br />
              i) To process requests and filed claims within legal the terms
              specified by law.
              <br />
              <br />
              j) To identify when specific information is under discussion by
              the holder.
              <br />
              <br />
              k) To inform upon holder’s request about the use of his or her
              data.
              <br />
              <br />
              l) To inform the data protection authority when a breaching of the
              law codes occurs or should risks exists in the holder’s data
              handling procedure.
              <br />
              <br />
              m) To comply with the requirements and instructions that the
              Superintendence of Industry and Commerce resolves on a particular
              matter.
              <br />
              <br />
              n) To use only data whose handling is previously authorized in
              compliance with the 1581 act from year 2012.
              <br />
              <br />
              o) EXSIS will utilize the holder’s personal data only for the
              purposes it is entitled and respecting in all cases valid laws and
              regulations about data protection. <br />
            </p>
            <br />
            <p className="privacy__subtitle">VII. MINORS</p>
            <br />
            <p>
              Our services are only available for those individuals who have
              capacity to hire or to be hired. Therefore, those who do not
              comply with this requirement will need to avoid providing personal
              data to be included in our databases. Although this can be done
              with the parent’s help or tutors, according to what is written in
              our Terms and conditions, the tutor or legal representative of the
              minor will grant authorization once the child has exerted his or
              her right to be listened and his or her opinion has been valuated
              according to his or her maturity, autonomy and capacity to take
              care of this issue. Minors’ Personal data must respect the overall
              interest of children, girls and adolescents and protect their
              fundamental rights. In compliance with the 1377 Decree from year
              2013, EXSIS will not handle minors’ sentitive personal data,
              defined by the 1581 act from year 2012 as those which affect the
              holder’s intimacy or those whose improper use can generate
              discrimination, such as those that disclose trade union
              affiliations, racial or ethnic origin, political orientation,
              religious, moral or philosophical beliefs, the affiliation to
              trade unions, social or human rights organizations that promote
              political parties that guarantee rights and guarantee political
              parties opposition, as well as data related to health, sexual
              life, and biometric data.
            </p>
            <br />
            <p className="privacy__subtitle">
              VIII. HOLDER’S AUTHORIZATION AND ACKNOWLEDGEMENT
            </p>
            <br />
            <p>
              Subject to legal exceptions, the holder’s data handling requires
              previous, informed authorization from the holder, and this
              authorization will have to be obtained through any means that can
              be consulted later.
            </p>
            <br />
            <p className="privacy__subtitle">
              IX. PERSONAL DATA REQUEST PROCEDURE
            </p>
            <br />
            <p>
              EXSIS will inform in advance to the holders about the required
              personal data and the reason why the information is needed,
              understanding that the above mentioned purpose will always be
              related to EXSIS economic activity. Regarding the authorization
              obtention, EXSIS will gather data related to personal data in a
              strictly manner using the assigned forms and texts for this
              activity. <br />
              <br />
              In the documents, formats and/or texts that EXSIS assigns for the
              task the following will always be included:
              <br />
              <br />
              a. The mention of the personal data policy, in this document, and
              its location on the corporate website.
              <br />
              <br />
              b. Contact information regarding the responsible person for the
              data handling.
              <br />
              <br />
              c. A space for the holder’s signature or, in any particular case
              the legal representative, or legitimate third person for the
              exercise of the rights.
              <br />
            </p>
            <br />
            <p className="privacy__subtitle">
              PERSONAL DATA REQUEST FOR EMPLOYEES AND/OR CONTRACTORS:
            </p>
            <br />
            <p>
              EXSIS will implement a text, format and/or authorization in the
              labor and/or independent contracts, in order to authorize in
              advance and in expressed way the data privacy handling related to
              the execution of the corresponding contract, which will include
              the authorization to gather, modify or correct the holder’s
              personal data. <br />
              <br />
              The text, form and/or authorization will also include the
              authorization with the clarification that some of the data could
              be delivered to a third party with which EXSIS has a relation or
              outsourced tasks, which in any case will limit the use they give
              to the data.
            </p>
            <br />
            <p className="privacy__subtitle">
              X. MEANS AND DECLARATION TO GRANT THE HOLDER’S AUTHORIZATION
            </p>
            <br />
            <p>
              EXSIS, according to legal terms, generated a notification which
              communicates that holders can exert their “personal data handling”
              rights over the website www.exsis.co and the e-mail
              habeasdata@exsis.com.co.
            </p>
            <br />
            <p className="privacy__subtitle">
              XI. EVENTS WHEN THE HOLDER’S PERSONAL DATA AUTHORIZATION IS
              UNNECESSARY
            </p>
            <br />
            <p>
              The holder’s authorization information will not be necessary in
              the following cases:
            </p>
            <br />
            <p>
              a) Data required by a public or administrative entity in exercise
              of legal functions or by judicial requirement.
              <br />
              b) Data of public nature.
              <br />
              c) Medical or sanitary urgency cases.
              <br />
              d) Data handling authorized by law for historical, statistical or
              scientific purposes. Data related to people’s civil registration.
            </p>
            <br />
            <p className="privacy__subtitle">
              XII. LEGITIMIZATION FOR THE HOLDER’S RIGHT EXERCISE
            </p>
            <br />
            <p>
              The holder’s rights established in the Law will be exerted by the
              following individuals: <br />
              a) By the holder, who will have to credit his or her identity in a
              sufficient manner throughout the possible means that EXSIS offers
              to him or her
              <br />
              b) By the holder’s successors, who will have to credit such
              quality.
              <br />
              c) By the representative and/or holder’s power of attorney,
              previous to the full accreditation of the representation or the
              power of attorney.
              <br />
              d) By stipulation in favor of someone or for someone.
            </p>
            <br />
            <p className="privacy__subtitle">
              XIII. PROCEDURE THAT DATA WILL UNDERGO AND ITS PURPOSE
            </p>
            <br />
            <p>
              The employees and customer’s mandatory personal data handling will
              be framed in the legality in order to provide an excellent service
              and to make more effective, agile and safer operations that users
              execute, EXSIS will gather the holder’s personal data only for the
              following purposes:
            </p>
            <br />
            <p>
              a) To make EXSIS transactions more effective and safer <br />
              <br />
              b) To comply with customers and users’ service agreements, in
              compliance with the objectives to provide offers, sales, and EXSIS
              services or products.
              <br />
              <br />
              c) To complement the data and, in general, to move forward the
              necessary activities to manage requests, complaints and claims
              filed by EXISIS customers or users and by third parties, to
              redirect them to responsible areas to provide the corresponding
              responses.
              <br />
              <br />
              d) To send information and EXSIS commercial proposals about
              products, as well as to have marketing and/or services
              commercialization activities and/or products that EXSIS could
              possibly offer.
              <br />
              <br />
              e) To conduct market research, statistics, surveys, market trends
              analysis, satisfaction surveys about services provided by EXSIS.
              <br />
              <br />
              f) For personal data transmission to a third party with whom
              agreement were made with this intention for commercial,
              administrative and/or operational purposes.
              <br />
              <br />
              g) To manage all necessary data for EXSIS tax, commercial records,
              corporate and accounting obligations compliance.
              <br />
              <br />
              h) To be able to proceed with billing/invoicing and EXSIS cash
              collections.
              <br />
              <br />
              i) To send information or messages about new products and/or
              services, to show the current advertisement or promotion, banners,
              EXSIS news and any other information we think is convenient.
              <br />
              <br />
              j) To share personal data with service companies or outsourcing
              companies that contribute to facilitate operations with EXSIS,
              including, payment methods, insurance or payment management
              intermediaries. EXSIS will do its best to create third parties’
              policies with similar standars to those contained in this
              document, using agreements and contracts both with signatures.
              <br />
              <br />
              k) To provide the holder’s personal data to entities involved with
              conflicts resolution that are qualified to do so.
            </p>
            <br />
            <p>
              In case of sensitive personal data, it will be possible to use the
              data handling when:
            </p>
            <br />
            <p>
              a) The Holder has given explicit authorization for the data
              handling, except for cases where the authorization is unnecessary
              by law; <br />
              <br />
              b) Data handling is necessary to safeguard the holder’s vital
              interest and when the holder is physically or juridically
              handicapped. In these events, the legal representatives will have
              to grant their authorization;
              <br />
              <br />
              c) The handling happens during the legitimate activities
              guaranteed by a foundation, ONG, association or any other
              non-profit organism, whose political, philosophical, religious
              intention or trade union intention, only if they refer exclusively
              to their members or to people who maintain regular contact due to
              the intention. In this case, data will not be given to a third
              person without the holder’s authorization;
              <br />
              <br />
              d) The data handling refers to data that is necessary for the
              acknoledgement, exercise or defense of a right in a judicial
              process;
              <br />
              <br />
              e) The handling has a historical, statistical or scientific
              purpose. In this case measures need to be taken to end up with the
              holder’s identity deletion.
            </p>
            <br />
            <p className="privacy__subtitle">
              XIV. INDIVIDUALS TO WHOM DATA CAN BE GIVEN
            </p>
            <br />
            <p>
              The information that complies with the established conditions by
              law can be provided to the following individuals:
              <br />
              a) To the holders, their successors (if they are missing) or their
              legal representatives.
              <br />
              b) To public or administrative entities in exercise of their legal
              functions or by judicial order.
              <br />
              c) To a third person authorized by the holder or by the law.
            </p>
            <br />
            <p className="privacy__subtitle">
              XV. RESPONSIBLE PERSON OR AREA FOR REQUESTS AND CLAIMS SERVICE
            </p>
            <br />
            <p>
              EXSIS has assigned as the responsible area to take care of
              compliance of this policies within the company - the configuration
              management area- with support of the juridical direction,
              functional areas that coordinate and handle the holder’s personal
              data and data security professionals.
            </p>
            <br />
            <p>
              This department will be ready to resolve requests, inquiries and
              claims from the holders and to update, modify, delete personal
              data over the e-mail habeasdata@exsis.com.co
            </p>
            <br />
            <p className="privacy__subtitle">
              XVI. PROCEDURE TO TAKE CARE OF CONSULTATIONS, CLAIMS AND REQUESTS
            </p>
            <br />
            <p>
              EXSIS has a Manual of complaints and claims (PR_003 Complaints and
              Claims), the above mentioned document specifies the procedure that
              is demanded to resolve issues.
            </p>
            <br />
            <p>
              <span className="privacy__subtitle">a) consultations:</span>
              <br />
              <br />
              The Holders or their successors will be able to consult the
              holder’s personal data which EXSIS has, EXSIS will give all data
              contained in individual records that is linked to the holder’s
              identification. <br />
              The consultation will be sent through the e-mail
              habeasdata@exsis.com.co The consultation will be taken care of
              with a deadline of twenty-four (24) hours from its receiption
              date. When it woul not be possible to respond within thee above
              mentioned term, the interested party will be informed, expressing
              the reasons of the delay and indicating the resolution date for
              the consultation, which needs to be provided not later than within
              five (5) working days from the first expiration date.
              <br />
              <br />
              <span className="privacy__subtitle">b) Claims:</span> <br />
              <br />
              The Holder or his or her successors who consider the data
              contained in a database must be object of correction, update or
              deletion, or when they warn a possible non compliance of any of
              the legal duties they will be able to file a claim before EXSIS
              which will be handled with following the rules below: <br />
              <br />
              i. The holder’s claim will be done directly to EXSIS and the claim
              should be sent to the e-mail habeasdata@exsis.com.co with the
              holder’s identity, the facts description which originated the
              claim, the address, and with all necessary support documentation.
              If the claim is incomplete, the interested party will be required
              to provide complete information within twenty-four (24) hours. two
              (2) months later after the requirement, without providing the
              required data, is understood as cancelation of the right to claim.
              <br />
              <br />
              ii. if the claim receiver is not competent to resolve it, it will
              transfer the claim to a competent person in a maximum term of two
              (2) working days and he or she will inform about the situation to
              the interested party.
              <br />
              <br />
              iii. Once the e-mail is received in the e-mail address
              habeasdata@exsis.com.co with complete information about the claim,
              it will be flagged with "claim in process" and the reason why it
              occurred in not later than two (2) working days. The above
              mentioned tag will be there until it gets a final resolution.
              <br />
              <br />
              iv. The maximum deadline to take care of the claim will be fifteen
              (15) working days from the day of reception. If it was not
              possible to take care of the claim within the above mentioned
              term, the the interested party will be informed of the delay and
              the reasons for this to happen and date to look into the claim,
              which cannot exceed eight (8) working days from the first
              expiration date.
              <br />
              <br />
              <span className="privacy__subtitle">
                c) Request of update, modification and data suppression.
              </span>
              <br />
              <br />
              EXSIS will modify and update, upon holder’s request, data which is
              incomplete or inaccurate, according to the procedure and the terms
              in this document, for this the holder will send the request to the
              e-mail habeasdata@exsis.com.co indica- ting the update,
              modification and data suppression and will provide support
              documentation for his request.
              <br />
              <br />
              <span className="privacy__subtitle">
                d) Revocation of the authorization and/or data suppression
              </span>
              <br />
              <br />
              The holders’ personal data can revoke the personal data handling
              authorization at any moment, and any time only if a legal
              disposition does not prevent it from happening, for this EXSIS
              will provide the holder with the e-mail habeasdata@ exsis.com.co.
              <br />
              <br />
              If the respective legal term is due, EXSIS, according to the case,
              has not deleted the personal data, the Holder will request the
              Supervision of Industry and Commerce to revoke the authorization
              and/or the personal data suppression. In this situation the
              procedure described in the 22nd article of the 1581 Act from year
              2012 will be applied.
              <br />
            </p>
            <br />
            <p className="privacy__subtitle">
              XVII. VALIDITY OF THE POLICIES FOR THE HOLDER’S PERSONAL DATA
              HANDLING
            </p>
            <br />
            <p className="mb-5">
              The Policy is available to the Holders of Data from the 1st of
              July year 2013 and can be consulted on the website "www.exsis.co"
              <br />
              <br />
              Any substantial modification to the policy about the responsible
              person or purposes of data handling will be notified before
              implementing any changes to the Holders using the web page
              "www.exsis.co" or any other efficient mechanism for this purpose.
            </p>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="privacy__container">
        <div className="privacy__content">
          <div className="privacy__close-container">
            <span
              className="privacy__span-close text-blue_dark"
              onClick={() => setOpenPrivacyPolicy(false)}
            ></span>
          </div>
          <p className="privacy__title">
            
            POLÍTICA DE TRATAMIENTO PROTECCIÓN DE DATOS PERSONALES DE LOS
            CLIENTES DE EXSIS DIGITAL ANGELS S.A.S.
          </p>
          <div className="privacy__text">
            <br />
            <p>
              Debido a la actividad comercial que desarrolla Exsis Digital Angels, 
              y dando cumplimiento al Decreto 1377 de 2013, se
              establece la política de tratamiento de datos personales que Exsis
              Digital Angels lleva a cabo.
            </p>
            <br />
            <p className="privacy__subtitle">I. IDENTIFICACIÓN</p>
            <br />
            <p>
              NOMBRE DE LA INSTITUCIÓN: EXSIS DIGITAL ANGELS S.A.S (en
              adelante EXSIS), empresa, de recursos y capital netamente
              colombiano, perteneciente al régimen común y dedicada a ofrecer
              soluciones de software que permitan satisfacer las necesidades y
              expectativas de manejo de la información de sus clientes,
              utilizando los últimos avances tecnológicos y asegurando calidad
              tanto en la creación de productos como en la prestación de
              servicios.
            </p>
            <br />
            <p>
              DOMICILIO: Bogotá. <br />
              DIRECION: Calle 145 # 48-16, Bogotá D.C. <br />
              CORREO ELECTRÓNICO: habeasdata@exsis.com.co <br />
              TELEFÓNO DEL RESPONSABLE: 4846750 Ext. 1021
              <br />
              <br />
              <span className="privacy__subtitle">II. MARCO LEGAL </span>
              <br />
              <br />
              Constitución Política, artículo 15. <br />
              Ley 1266 de 2008 <br />
              Ley 1581 de 2012 <br />
              Decretos Reglamentarios 1727 de 2009 y 2952 de 2010, <br />
              Decreto Reglamentario parcial 1377 de 2013 <br />
            </p>
            <br />
            <p className="privacy__subtitle">III. DEFINICIONES </p>
            <br />
            <p>
              <span className="privacy__subtitle">AUTORIZACIÓN: </span>
              consentimiento previo, expreso e informado del titular para llevar
              a cabo el tratamiento de datos personales. <br /> <br />
              <span className="privacy__subtitle">AVISO DE PRIVACIDAD: </span>
              comunicación verbal o escrita generada por el responsable dirigida
              al titular para el tratamiento de sus datos personales, mediante
              la cual se le informa acerca de la existencia de las políticas de
              tratamiento de información que le serán aplicables, la forma de
              acceder a las mismas y las finalidades del tratamiento que se
              pretende dar a los datos personales. <br /> <br />
              <span className="privacy__subtitle">BASE DE DATOS: </span>conjunto
              organizado de datos personales que sea objeto de tratamiento.
              <br />
              <br />
              <span className="privacy__subtitle">CAUSAHABIENTE: </span>persona
              que ha sucedido a otra por causa del fallecimiento de ésta
              (heredero).
              <br />
              <br />
              <span className="privacy__subtitle">DATO PERSONAL: </span>
              cualquier pieza de información vinculada a una o varias personas
              determinadas o determinables o que puedan asociarse a una persona
              natural o jurídica.
              <br />
              <br />
              <span className="privacy__subtitle">DATO PÚBLICO: </span>es el
              dato que no sea semiprivado, privado o sensible. Son considerados
              datos públicos, entre otros, los datos relativos al estado civil
              de las personas, a su profesión u oficio y a su calidad de
              comerciante o de servidor público. Por su naturaleza, los datos
              públicos pueden estar contenidos, entre otros, en registros
              públicos, documentos públicos, gacetas y boletines oficiales y
              sentencias judiciales debidamente ejecutoriadas que no estén
              sometidas a reserva. <br />
              <br />
              <span className="privacy__subtitle">DATOS SENSIBLES: </span>se
              entiende por datos sensibles aquellos que afectan la intimidad del
              titular o étnico, la orientación política, las convicciones
              religiosas o filosóficas, la pertenencia a sindicatos,
              organizaciones sociales, de derechos humanos o que promueva
              intereses de cualquier partido político o que garanticen los
              derechos y garantías de partidos políticos de oposición, así como
              los datos relativos a la salud, a la vida sexual, y los datos
              biométricos.
              <br />
              <br />
              <span className="privacy__subtitle">DATOS INDISPENSABLES: </span>
              se entienden como aquellos datos personales de los titulares
              imprescindibles para llevar a cabo la actividad de la empresa. Los
              datos de naturaleza indispensable deberán ser proporcionados por
              los titulares de los mismos o los legitimados para el ejercicio de
              estos derechos.
              <br />
              <br />
              <span className="privacy__subtitle">
                ENCARGADO DEL TRATAMIENTO:
              </span>
              persona natural o jurídica, pública o privada que por sí misma o
              en asocio con otros, realice el Tratamiento de datos personales
              por cuenta del Responsable del Tratamiento.
              <br />
              <br />
              <span className="privacy__subtitle">
                LEY DE PROTECCIÓN DE DATOS:
              </span>
              es la Ley 1581 de 2012 y sus Decretos reglamentarios o las normas
              que los modifiquen, complementen o sustituyan.
              <br />
              <br />
              <span className="privacy__subtitle">HABEAS DATA: </span>derecho de
              cualquier persona a conocer, actualizar y rectificar las
              informaciones que se hayan recogido sobre ellas en el banco de
              datos y en archivos de entidades públicas y privadas.
              <br />
              <br />
              <span className="privacy__subtitle">
                RESPONSABLE DEL TRATAMIENTO:
              </span>
              : persona natural o jurídica, pública o privada que por sí misma o
              en asocio con otros, decida sobre la base de datos y/o Tratamiento
              de los datos.
              <br />
              <br />
              <span className="privacy__subtitle">TITULAR: </span>persona
              natural cuyos datos personales sean objeto de Tratamiento.
              <br />
              <br />
              <span className="privacy__subtitle">TRATAMIENTO: </span>cualquier
              operación o conjunto de operaciones sobre datos personales, tales
              como la recolección, almacenamiento, uso, circulación o supresión.
              <br />
              <br />
              <span className="privacy__subtitle">TRANSFERENCIA: </span>la
              transferencia de datos tiene lugar cuando el responsable y/o
              encargado del tratamiento de datos personales, ubicado en
              Colombia, envía la información o los datos personales a un
              receptor, que a su vez es responsable del tratamiento y se
              encuentra dentro o fuera del país.
              <br />
              <br />
              <span className="privacy__subtitle">TRANSMISIÓN: </span>
              tratamiento de datos personales que implica la comunicación de los
              mismos dentro o fuera del territorio de la República de Colombia
              cuando tenga por objeto la realización de un tratamiento por el
              encargado por cuenta del responsable.
              <br />
            </p>
            <br />
            <p className="privacy__subtitle">IV. PRINCIPIOS</p>
            <br />
            <p>
              En el desarrollo, interpretación y aplicación de la ley 1581 de
              2012 por la cual se dictan disposiciones generales para la
              protección de datos personales y las normas que la complementan,
              modifican o adicionan, se aplicarán de manera armónica e integral
              los siguientes principios rectores:
            </p>
            <br />
            <p>
              <span className="privacy__subtitle">
                a) PRINCIPIO DE LA LEGALIDAD:
              </span>
              el Tratamiento de datos es una actividad reglada que debe
              sujetarse a lo estableció en la ley y las demás disposiciones que
              la desarrollen.
              <br />
              <br />
              <span className="privacy__subtitle">
                b) PRINCIPIO DE FINALIDAD:
              </span>
              el tratamiento debe obedecer a una finalidad legítima de acuerdo
              con la Constitución y la Ley, la cual debe ser informada al
              titular. <br />
              <br />
              En lo correspondiente a la recolección de datos personales, EXSIS
              se limitará a aquellos datos que sean pertinentes y adecuados para
              la finalidad con la cual fueron recolectados o requeridos; se
              deberá informar al titular el motivo por el cual se solicita la
              información y el uso específico que se le dará a la misma.
              <br />
              <br />
              <span className="privacy__subtitle">
                c) PRINCIPIO DE LIBERTAD:
              </span>
              el tratamiento solo puede ejercerse con el consentimiento previo,
              expreso, e informado del titular. Los datos personales no podrán
              ser obtenidos o divulgados sin previa autorización, o en ausencia
              de mandato legal o judicial que releve el consentimiento.
              <br />
              <br />
              <span className="privacy__subtitle">
                d) PRINCIPIO DE VERACIDAD O CALIDAD:
              </span>
              la información sujeta a tratamiento debe ser veraz, completa,
              exacta, actualizada, comprobable y comprensible. Se prohíbe el
              tratamiento de datos parciales, incompletos, fraccionados o que
              induzcan a error.
              <br />
              <br />
              <span className="privacy__subtitle">
                e) PRINCIPIO DE TRANSPARENCIA:
              </span>
              en el tratamiento debe garantizarse el derecho del titular a
              obtener del responsable del tratamiento o del encargado del
              tratamiento, en cualquier momento y sin restricciones, información
              acerca de la existencia de datos que le conciernan.
              <br />
              <br />
              <span className="privacy__subtitle">
                f) PRINCIPIO DE ACCESO Y CIRCULACIÓN RESTRINGIDA:
              </span>
              el tratamiento se sujeta a los límites que se derivan de la
              naturaleza de los datos personales, de las disposiciones de la ley
              y la Constitución. En este sentido, el tratamiento sólo podrá
              hacerse por personas autorizadas por el titular y/o por las
              personas previstas en la ley. Los datos personales, salvo la
              información pública, no podrán estar disponibles en internet u
              otros medios de divulgación o comunicación masiva, salvo que el
              acceso sea técnicamente controlable para brindar un conocimiento
              restringido sólo a los titulares o terceros autorizados conforme a
              la ley.
              <br />
              <br />
              <span className="privacy__subtitle">
                g) PRINCIPIO DE SEGURIDAD:
              </span>
              la información sujeta a tratamiento por la EXSIS, se deberá
              manejar con las medidas técnicas, humanas y administrativas que
              sean necesarias para otorgar seguridad a los registros evitando su
              adulteración, pérdida, consulta, uso o acceso no autorizado o
              fraudulento.
              <br />
              <br />
              <span className="privacy__subtitle">
                h) PRINCIPIO DE CONFIDENCIALIDAD
              </span>
              : EXSIS está obligada a garantizar la reserva de la información,
              inclusive después de finalizada su relación con alguna de las
              labores que comprende el tratamiento, pudiendo sólo realizar
              suministro o comunicación de datos personales cuando ello
              corresponda al desarrollo de las actividades autorizadas en la
              ley.
              <br />
            </p>
            <br />
            <p className="privacy__subtitle">V. DERECHOS</p>
            <br />
            <p>
              EXSIS está comprometido con proteger la privacidad y garantizar
              los derechos al habeas data para que los clientes puedan conocer,
              actualizar y rectificar los datos personales que administren
              EXSIS. Por ello, se establece que, de acuerdo con la Ley 1581 de
              2012 y el Decreto 1377 de 2013, como el titular de datos
              personales, cuentan con los siguientes derechos:
            </p>
            <br />
            <p>
              a) Conocer, actualizar y rectificar sus datos personales frente a
              EXSIS o los encargados, en su condición de responsable del
              tratamiento. Este derecho se podrá ejercer, entre otros, frente a
              datos personales Parciales, inexactos, incompletos, fraccionados,
              que induzcan a error o aquellos cuyo Tratamiento esté expresamente
              prohibido o no haya sido autorizado.
              <br />
              <br />
              b) Solicitar prueba de la autorización otorgada a EXSIS, salvo que
              exista una disposición legal que indique que dicha autorización no
              es necesaria o que la misma haya sido validada con arreglo a lo
              establecido en el artículo 10 del Decreto 1377.
              <br />
              <br />
              c) Presentar solicitudes a EXSIS respecto del uso que le han dado
              a sus datos personales, y a que éstas le entreguen tal
              información.
              <br />
              <br />
              d) Presentar ante la Superintendencia de Industria y Comercio
              quejas por infracciones a la Ley 1581 de 2012 y al Decreto 1377 de
              2013.
              <br />
              <br />
              e) Revocar su autorización y/o solicitar la supresión de sus datos
              personales de las bases de datos de EXSIS, siempre y cuando no
              exista un deber legal (cuando el usuario ha realizado compras
              existe una obligación legal y tributaria de dejar el registro de
              las compras realizadas, por lo cual esta información debe
              permanecer en el registro de ventas de EXSIS, lo anterior con
              fundamento al artículo 60 C.Co) ó una obligación de carácter
              contractual en cabeza del titular EXSIS, en virtud de la cual el
              titular no tenga el derecho de solicitar la supresión de sus datos
              personales o revocar su autorización para el Tratamiento de los
              mismos. Si no hay un deber legal o contractual y EXSIS no ha
              suprimido los datos personales del titular de sus bases de datos o
              no ha revocado la autorización de quien está legitimado para
              revocarla dentro del término legal para ello, el titular podrá
              acudir a la Superintendencia de Industria y Comercio para que
              exija la revocación de la autorización y/o la supresión de los
              datos personales.
              <br />
              <br />
              f) Solicitar acceso y acceder en forma gratuita a sus datos
              personales que hayan sido objeto de Tratamiento.
              <br />
              <br />
              Esta Política garantiza que cualquier información que se provea
              será mantenida de manera privada y segura. Para dar fe de esto, en
              este documento proveemos los detalles de qué información recabamos
              y de qué manera la utilizamos. Nunca recolectamos información sin
              el consentimiento explícito, previo e informado, a menos que sus
              datos personales hayan sido tratados por EXSIS con anterioridad de
              la expedición del Decreto 1377 de 2013 y se haya llevado a cabo el
              procedimiento establecido el artículo 10 del mencionado Decreto.
              Este documento es parte integrante de los Términos y Condiciones
              de EXSIS. Mediante la aceptación de los Términos y Condiciones
              Usted reconoce que ha sido informado sobre nuestra Política.
            </p>
            <br />
            <p className="privacy__subtitle">VI. DEBERES</p>
            <br />
            <p>
              En virtud de la presente política de tratamiento y protección de
              datos personales son deberes de EXSIS los siguientes, sin
              perjuicio de las disposiciones previstas en la ley.
            </p>
            <br />
            <p>
              a) Garantizar al titular, en todo tiempo, el pleno y efectivo
              ejercicio del derecho de hábeas data.
              <br />
              <br />
              b) Solicitar y conservar, copia de la respectiva autorización
              otorgada por el titular.
              <br />
              <br />
              c) Informar debidamente al titular sobre la finalidad de la
              recolección y los derechos que le asisten en virtud de la
              autorización otorgada.
              <br />
              <br />
              d) Conservar la información bajo las condiciones de seguridad
              necesarias para impedir su adulteración, pérdida, consulta, uso o
              acceso no autorizado o fraudulento.
              <br />
              <br />
              e) Garantizar que la información sea veraz, completa, exacta,
              actualizada, comprobable y comprensible.
              <br />
              <br />
              f) Actualizar la información, atendiendo de esta forma todas las
              novedades respecto de los datos del titular. Adicionalmente, se
              deberán implementar todas las medidas necesarias para que la
              información se mantenga actualizada.
              <br />
              <br />
              g) Rectificar la información cuando sea incorrecta y comunicar lo
              pertinente.
              <br />
              <br />
              h) Respetar las condiciones de seguridad y privacidad de la
              información del titular.
              <br />
              <br />
              i) Tramitar las consultas y reclamos formulados en los términos
              señalados por la ley.
              <br />
              <br />
              j) Identificar cuando determinada información se encuentra en
              discusión por parte del titular.
              <br />
              <br />
              k) Informar a solicitud del titular sobre el uso dado a sus datos.
              <br />
              <br />
              l) Informar a la autoridad de protección de datos cuando se
              presenten violaciones a los códigos de seguridad y existan riesgos
              en la administración de la información de los titulares.
              <br />
              <br />
              m) Cumplir los requerimientos e instrucciones que imparta la
              Superintendencia de Industria y Comercio sobre el tema en
              particular.
              <br />
              <br />
              n) Usar únicamente datos cuyo tratamiento esté previamente
              autorizado de conformidad con lo previsto en la ley 1581 de 2012.
              <br />
              <br />
              o) EXSIS hará uso de los datos personales del titular solo para
              aquellas finalidades para las que se encuentre facultada
              debidamente y respetando en todo caso la normatividad vigente
              sobre protección de datos personales.
              <br />
              <br />
            </p>
            <br />
            <p className="privacy__subtitle">VII. MENORES DE EDAD</p>
            <br />
            <p>
              Nuestros servicios sólo están disponibles para aquellas personas
              que tengan capacidad legal para contratar. Por lo tanto, aquellos
              que no cumplan con esta condición deberán abstenerse de
              suministrar información personal para ser incluida en nuestras
              bases de datos. Aunque pueden hacerlo a través de los padres,
              tutores o curadores, conforme lo establecido en nuestros Términos
              y Condiciones, el tutor o representante legal del menor de edad
              otorgará su autorización una vez el menor haya ejercido su derecho
              de ser escuchado y se haya valorado su opinión en conformidad con
              su madurez, autonomía y capacidad para atender el asunto. Los
              datos personales de los menores de edad deben respetar el interés
              superior de los niños, niñas y adolescentes y se protejan sus
              derechos fundamentales. De acuerdo con el Decreto 1377 de 2013,
              EXSIS no tratará datos sensibles de menores de edad, definidos
              éstos últimos por la Ley 1581 de 2012 como aquellos que afectan la
              intimidad del titular o cuyo uso indebido puede generar su
              discriminación, tales como aquellos que revelen afiliaciones
              sindicales, el origen racial o étnico, la orientación política,
              las convicciones religiosas, morales o filosóficas, la pertenencia
              a sindicatos, organizaciones sociales, de derechos humanos o que
              promuevan intereses de cualquier partido político o que garanticen
              los derechos y garantías de partidos políticos de oposición, así
              como los datos relativos a la salud, a la vida sexual, y los datos
              biométricos.
            </p>
            <br />
            <p className="privacy__subtitle">
              VIII. AUTORIZACIONES Y CONSENTIMIENTO DEL TITULAR
            </p>
            <br />
            <p>
              Sin perjuicio de las excepciones previstas en la Ley, en el
              tratamiento de datos personales del titular se requiere la
              autorización previa e informada de éste, la cual deberá ser
              obtenida por cualquier medio que pueda ser objeto de consulta
              posterior.
            </p>
            <br />
            <p className="privacy__subtitle">
              IX. PROCEDIMIENTO PARA LA SOLICITUD DE DATOS PERSONALES
            </p>
            <br />
            <p>
              EXSIS informará previamente a los titulares los datos personales
              que se requieren y el motivo por el cual se solicita la
              información, entendiendo que dicha finalidad estará siempre
              relacionada con las actividades que se derivan de la actividad
              económica de EXSIS. <br />
              <br />
              En lo correspondiente al modo de obtener la autorización, EXSIS
              recolectará la información correspondiente a datos personales de
              manera escrita a través de los formatos y textos que se
              establezcan para el efecto. En los documentos, formatos y/o textos
              que EXSIS establezca para el efecto se incluirá siempre:
              <br />
              <br />
              a. La mención de la presente política de tratamiento y su
              ubicación en la web institucional.
              <br />
              <br />
              b. Datos de contacto del responsable del tratamiento.
              <br />
              <br />
              c. Espacio para la firma del titular y, en caso dado representante
              legal, y/o cualquier otro legitimado para el ejercicio de los
              derechos.
              <br />
            </p>
            <br />
            <p className="privacy__subtitle">
              SOLICITUD DE DATOS PERSONALES PARA TRABAJADORES Y/O CONTRATISTAS:
            </p>
            <br />
            <p>
              EXSIS implementará en los contratos laborales y/o de prestación de
              servicios un texto, formato y/o autorización con el fin de
              autorizar de manera previa y expresa el tratamiento de datos
              personales relacionados con la ejecución del contrato
              correspondiente, lo que incluirá la autorización de recolectar,
              modificar o corregir, datos personales del titular. <br />
              <br />
              El texto, formato y/o autorización también incluirá la
              autorización de que algunos de los datos, puedan ser entregados a
              terceros con los cuales EXSIS tenga tareas tercerizadas las cuales
              en todo caso se limitarán en el uso que estos terceros le puedan
              dar a los datos.
            </p>
            <br />
            <p className="privacy__subtitle">
              X. MEDIO Y MANIFESTACIÓN PARA OTORGAR LA AUTORIZACIÓN DEL TITULAR
            </p>
            <br />
            <p>
              EXSIS en los términos dispuestos en la Ley generó un aviso en el
              cual se comunica a los titulares que pueden ejercer su derecho al
              tratamiento de los datos personales a través de la página
              www.exsis.co y del correo electrónico habeasdata@exsis.com.co.
            </p>
            <br />
            <p className="privacy__subtitle">
              XI. EVENTOS EN LOS CUALES NO ES NECESARIA LA AUTORIZACIÓN DEL
              TÍTULAR DE LOS DATOS PERSONALES
            </p>
            <br />
            <p>
              La autorización del titular de la información no será necesaria en
              los siguientes casos:
            </p>
            <br />
            <p>
              a) Información requerida por una entidad pública o administrativa
              en ejercicio de sus funciones legales o por orden judicial.
              <br />
              b) Datos de naturaleza pública.
              <br />
              c) Casos de urgencia médica o sanitaria.
              <br />
              d) Tratamiento de información autorizado por la ley para fines
              históricos, estadísticos o científicos. Datos relacionados con el
              Registro Civil de las personas.
            </p>
            <br />
            <p className="privacy__subtitle">
              XII. LEGITIMACIÓN PARA EL EJERCICIO DEL DERECHO DEL TITULAR
            </p>
            <br />
            <p>
              Los derechos de los titulares establecidos en la Ley podrán
              ejercerse por las siguientes personas: <br />
              a) Por el titular, quien deberá acreditar su identidad en forma
              suficiente por los distintos medios que le ponga a disposición
              EXSIS
              <br />
              b) Por los causahabientes del titular, quienes deberán acreditar
              tal calidad.
              <br />
              c) Por el representante y/o apoderado del titular, previa
              acreditación de la representación o apoderamiento.
              <br />
              d) Por estipulación a favor de otro o para otro.
            </p>
            <br />
            <p className="privacy__subtitle">
              XIII. TRATAMIENTO AL CUAL SERÁN SOMETIDOS LOS DATOS Y FINALIDAD
              DEL MISMO
            </p>
            <br />
            <p>
              El tratamiento para los datos personales indispensables de cliente
              y trabajadores estará enmarcado en el orden legal y con el fin de
              suministrar un excelente servicio y hacer más efectivas, ágiles y
              seguras las operaciones que los usuarios realicen, EXSIS
              recolectará los datos personales de los usuarios únicamente para
              las siguientes finalidades:
            </p>
            <br />
            <p>
              a) Para hacer más efectivas y seguras las transacciones que se
              lleven a cabo en EXSIS
              <br />
              <br />
              b) Para cumplir a cabalidad con los servicios celebrados con los
              clientes y usuarios, de acuerdo con sus finalidades para brindar
              las ofertas, promociones, servicios o productos de EXSIS.
              <br />
              <br />
              c) Para complementar la información y, en general, adelantar las
              actividades necesarias para gestionar las solicitudes, quejas y
              reclamos presentadas por los clientes o usuarios de EXSIS y por
              terceros, y direccionarlas a las áreas responsables de emitir las
              respuestas correspondientes.
              <br />
              <br />
              d) Enviar información y ofertas comerciales de productos de EXSIS,
              así como realizar actividades de mercadeo y/o comercialización de
              servicios y/o productos que preste o que pudiera llegar a prestar
              u ofrecer EXSIS.
              <br />
              <br />
              e) Elaborar estudios de mercado, estadísticas, encuestas, análisis
              de tendencias del mercado, encuestas de satisfacción sobre los
              servicios prestados por EXSIS.
              <br />
              <br />
              f) Para la transmisión de datos personales a terceros con los
              cuales se hayan celebrado contratos con este objeto, para fines
              comerciales, administrativos y/o operativos.
              <br />
              <br />
              g) Gestionar toda la información necesaria para el cumplimiento de
              las obligaciones tributarias y de registros comerciales,
              corporativos y contables de EXSIS.
              <br />
              <br />
              h) Para proceder a la facturación y cobro del EXSIS.
              <br />
              <br />
              i) Para enviar información o mensajes sobre los nuevos productos
              y/o servicios, mostrar la publicidad o promoción del momento,
              banners, noticias sobre EXSIS y toda otra información que creamos
              conveniente.
              <br />
              <br />
              j) Compartir los datos personales con empresas de servicios o
              empresas de “outsourcing” que contribuyan a mejorar o a facilitar
              las operaciones a través de EXSIS, dentro de las que se incluyen,
              medios de pago, seguros o intermediarios de la gestión de pagos.
              EXSIS velará porque las políticas de los terceros tengan
              estándares similares a los de la presente Política, mediante la
              firma de acuerdos, convenios y/o contratos.
              <br />
              <br />
              k) Para suministrar los datos personales de los titulares a las
              entidades que intervengan en la resolución de conflictos y que
              tengan competencia para ello.
            </p>
            <br />
            <p>
              Para el caso de datos personales sensibles, se podrá hacer uso y
              tratamiento de ellos cuando:
            </p>
            <br />
            <p>
              a) El Titular haya dado su autorización explícita a dicho
              Tratamiento, salvo en los casos que por ley no sea requerido el
              otorgamiento de dicha autorización;
              <br />
              <br />
              b) El Tratamiento sea necesario para salvaguardar el interés vital
              del Titular y este se encuentre física o jurídicamente
              incapacitado. En estos eventos, los representantes legales deberán
              otorgar su autorización;
              <br />
              <br />
              c) El Tratamiento sea efectuado en el curso de las actividades
              legítimas y con las debidas garantías por parte de una fundación,
              ONG, asociación o cualquier otro organismo sin ánimo de lucro,
              cuya finalidad sea política, filosófica, religiosa o sindical,
              siempre que se refieran exclusivamente a sus miembros o a las
              personas que mantengan contactos regulares por razón de su
              finalidad. En estos eventos, los datos no se podrán suministrar a
              terceros sin la autorización del Titular;
              <br />
              <br />
              d) El Tratamiento se refiera a datos que sean necesarios para el
              reconocimiento, ejercicio o defensa de un derecho en un proceso
              judicial;
              <br />
              <br />
              e) El Tratamiento tenga una finalidad histórica, estadística o
              científica. En este evento deberán adoptarse las medidas
              conducentes a la supresión de identidad de los Titulares.
            </p>
            <br />
            <p className="privacy__subtitle">
              XIV. PERSONAS A QUIENES SE LES PUEDE SUMINISTRAR LA INFORMACIÓN
            </p>
            <br />
            <p>
              La información que reúna las condiciones establecidas en la ley
              podrá suministrarse a las siguientes personas:
              <br />
              a) A los titulares, sus causahabientes (cuando aquellos falten) o
              sus representantes legales.
              <br />
              b) A las entidades públicas o administrativas en ejercicio de sus
              funciones legales o por orden judicial.
              <br />
              c) A los terceros autorizados por el titular o por la ley.
            </p>
            <br />
            <p className="privacy__subtitle">
              XV. PERSONA O ÁREA RESPONSABLE DE LA ATENCIÓN DE PETICIONES,
              CONSULTAS Y RECLAMOS
            </p>
            <br />
            <p>
              EXSIS ha designado como área responsable de velar por el
              cumplimiento de esta política al interior de la empresa al área de
              Administración de la Configuración con el apoyo de la Dirección
              Jurídica, áreas Funcionales que manejan los Datos Personales de
              los Titulares y profesionales en Seguridad de la Información.
            </p>
            <br />
            <p>
              Esta dependencia estará atenta para resolver peticiones, consultas
              y reclamos por parte de los titulares y para realizar cualquier
              actualización, rectificación y supresión de datos personales, a
              través del correo electrónico habeasdata@exsis.com.co
            </p>
            <br />
            <p className="privacy__subtitle">
              XVI. PROCEDIMIENTO PARA LA ATENCIÓN DE CONSULTAS, RECLAMOS Y
              PETICIONES
            </p>
            <br />
            <p>
              EXSIS cuenta con un Manual de quejas y reclamos (PR_003 Quejas y
              Reclamos), dicho documento específica el procedimiento exigido
              para la resolución de estas.
            </p>
            <br />
            <p>
              <span className="privacy__subtitle">a) Consultas:</span> <br />
              <br />
              Los Titulares o sus causahabientes podrán consultar la información
              personal del Titular que repose en EXSIS quien suministrará toda
              la información contenida en el registro individual o que esté
              vinculada con la identificación del Titular. <br />
              La consulta se formulará a través del correo
              habeasdata@exsis.com.co <br />
              La consulta será atendida en un término máximo de diez (24) horas
              a partir de la fecha de recibo de la misma. Cuando no fuere
              posible atender la consulta dentro de dicho término, se informará
              al interesado, expresando los motivos de la demora y señalando la
              fecha en que se atenderá su consulta, la cual en ningún caso podrá
              superar los cinco (5) días hábiles siguientes al vencimiento del
              primer término.
              <br />
              <br />
              <span className="privacy__subtitle">b) Reclamos:</span> <br />
              <br />
              El Titular o sus causahabientes que consideren que la información
              contenida en una base de datos debe ser objeto de corrección,
              actualización o supresión, o cuando adviertan el presunto
              incumplimiento de cualquiera de los deberes contenidos en la ley,
              podrán presentar un reclamo ante la EXSIS el cual será tramitado
              bajo las siguientes reglas: <br />
              <br />
              i. El reclamo del Titular se formulará mediante solicitud dirigida
              a EXSIS por el correo electrónico habeasdata@exsis.com.co con la
              identificación del Titular, la descripción de los hechos que dan
              lugar al reclamo, la dirección, y acompañando los documentos que
              se quiera hacer valer. Si el reclamo resulta incompleto, se
              requerirá al interesado dentro de las (24) horas siguientes a la
              recepción del reclamo para que subsane las fallas. Transcurridos
              dos (2) meses desde la fecha del requerimiento, sin que el
              solicitante presente la información requerida, se entenderá que ha
              desistido del reclamo.
              <br />
              <br />
              ii. En caso de que quien reciba el reclamo no sea competente para
              resolverlo, dará traslado a quien corresponda en un término máximo
              de dos (2) días hábiles e informará de la situación al interesado.
              <br />
              <br />
              iii. Una vez recibido el correo habeasdata@exsis.com.co con el
              reclamo completo, éste se catalogará con la etiqueta "reclamo en
              trámite" y el motivo del mismo en un término no mayor a dos (2)
              días hábiles. Dicha etiqueta se mantendrá hasta que el reclamo sea
              decidido.
              <br />
              <br />
              iv. El término máximo para atender el reclamo será de quince (15)
              días hábiles contados a partir del día siguiente a la fecha de su
              recibo. Cuando no fuere posible atender el reclamo dentro de dicho
              término, se informará al interesado los motivos de la demora y la
              fecha en que se atenderá su reclamo, la cual en ningún caso podrá
              superar los ocho (8) días hábiles siguientes al vencimiento del
              primer término.
              <br />
              <br />
              <span className="privacy__subtitle">
                c) Petición de actualización, rectificación y supresión de
                datos.
              </span>
              <br />
              <br />
              EXSIS rectificará y actualizará, a solicitud del titular, la
              información de éste que resulte ser incompleta o inexacta, de
              conformidad con el procedimiento y los términos antes señalados,
              para lo cual el titular allegará la solicitud al correo
              electrónico habeasdata@exsis.com.co indicando la actualización,
              rectificación y supresión del dato y aportará la documentación que
              soporte su petición. <br />
              <br />
              <span className="privacy__subtitle">
                d) Revocatoria de la autorización y/o supresión del dato
              </span>
              <br />
              <br />
              Los titulares de los datos personales pueden revocar el
              consentimiento al tratamiento de sus datos personales en cualquier
              momento, siempre y cuando no lo impida una disposición legal o
              contractual, para ello EXSIS pondrá a disposición del Titular el
              correo electrónico habeasdata@exsis.com.co.
              <br />
              Si vencido el término legal respectivo, EXSIS, según fuera el
              caso, no hubieran eliminado los datos personales, el Titular
              tendrá derecho a solicitar a la Superintendencia de Industria y
              Comercio que ordene la revocatoria de la autorización y/o la
              supresión de los datos personales. Para estos efectos se aplicará
              el procedimiento descrito en el artículo 22 de la Ley 1581 de
              2012.
              <br />
            </p>
            <br />
            <p className="privacy__subtitle">
              XVII. VIGENCIA DE LA POLÍTICA PARA EL TRATAMIENTO DE DATOS
              PERSONALES DE LOS TITULARES
            </p>
            <br />
            <p className="mb-5">
              La presente Política se encuentra a disposición de los Titulares
              de los Datos a partir de julio 01 de 2013 y puede ser consultada
              en la página web “www.exsis.co” <br />
              <br />
              Cualquier modificación sustancial a la misma referida a la
              identificación del responsable y a la finalidad del Tratamiento,
              será comunicada antes de implementar dicho cambio a los Titulares
              a través de la página web "www.exsis.co" o de cualquier otro
              mecanismo eficiente.
            </p>
          </div>
        </div>
      </div>
    );
  }
};

export default PrivacyPolicy;
