import React, { useState } from "react";

const PostsContext = React.createContext({});

export function PostsContextProvider({ children }) {
  const [postId, setPostId] = useState("");
  const [postSlug, setPostSlug] = useState("");
  const [post, setPost] = useState([]);
  const [image, setImage] = useState([]);
  const [images, setImages] = useState([]);
  const [posts, setPosts] = useState([]);

  return (
    <PostsContext.Provider
      value={{
        posts,
        setPosts,
        postId,
        setPostId,
        postSlug,
        setPostSlug,
        post,
        setPost,
        image,
        setImage,
        images,
        setImages,
      }}
    >
      {children}
    </PostsContext.Provider>
  );
}

export default PostsContext;
