import React, { Fragment,useContext } from "react";
import { useState } from "react";
import i18n from "i18next";
import ScrollTrigger from "react-scroll-trigger";
import { Link } from "gatsby";

// components
import PrivacyPolicy from "../privacyPolicy/PrivacyPolicy";

// styles
import "./Footer.css";

// images

import logoFooter from "../../../images/footer/LogoFooter.png";
import facebookF from "../../../images/footer/facebookFooter.png";
import xF from "../../../images/footer/twitterX2023Logo.png";
import whatsappF from "../../../images/footer/whatsappFooter.png";
import linkedinF from "../../../images/footer/linkedinFooter.png";
import WhatsAppImage from "../../../images/footer/whatsappbot.png";
import redSocial from "../../../images/footer/IGIcon.png";
import { LoadingContext } from '../../../components/context/LoadingContext';

const Footer = () => {
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
  const { windowWidth } = useContext(LoadingContext);
  function onEnterViewport() {
    setTimeout(() => {
      document
        .getElementById("logo-animated")
        .classList.add("xlogo__image--animation");
    }, 500);
  }

  function onExitViewport() {
    document
      .getElementById("logo-animated")
      .classList.remove("xlogo__image--animation");
  }

  return (
    <Fragment>
     
      <footer className="w-full flex flex-wrap border-grey pin-b bg-image image-footer-degraded image-footer-degraded-movil text-white -mt-1 mobile:py-24 mobile:px-8 landscape:pt-20 desktop:pr-32 desktop:pl-20 landscape:pb-32">
        <div className="w-full  landscape:text-left">
          <div className="flex justify-between flex-wrap mb-8">
           {windowWidth >= 1025 ?
            <div className="mobile:mr-0 landscape:mr-3 contentFirstFooter">
            <img className="footerLogo" src={logoFooter} alt="logo exsis" />
              <p className="mt-4 textLogoFooter">
              {i18n.t("home.footer.text")}
              </p>
              <div className="flex mt-4 ">
              <a
                      href="https://twitter.com/Exsisdigital"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
              <img className="mr-4 cursor-pointer" src={xF} alt="logo exsis" />
              </a>
              <a
                      href="https://www.linkedin.com/company/exsis-digital"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
              <img className="mr-4 cursor-pointer" src={linkedinF} alt="logo exsis" />
              </a>
              <a
                      href="https://www.facebook.com/Exsisdigital/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
              <img className="mr-4 cursor-pointer" src={facebookF} alt="logo exsis" />
              </a>
              <a
                      href="https://www.instagram.com/exsisdigital/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
              <img className="mr-4 cursor-pointer imgInsta" src={redSocial} alt="logo exsis" />
              </a>
              <a
                      href="https://wa.me/+573176448607"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
              <img className="mr-4 cursor-pointer" src={whatsappF} alt="logo exsis" />
              </a>
              </div>
              <Link to="/privacy-policy" className="block linksSectionFooter mt-6">
              {i18n.t("home.footer.textPolicy")}
              </Link>
            </div>:
            <div className="mobile:mr-0 landscape:mr-3 contentFirstFooter">
            <img className="footerLogo" src={logoFooter} alt="logo exsis" />
              <p className="mt-8 textLogoFooter">
              {i18n.t("home.footer.text")}
              </p>
              <div className="flex mt-12 mb-8">
              <a
                      href="https://twitter.com/Exsisdigital"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
              <img className="mr-4 cursor-pointer" src={xF} alt="logo exsis" />
              </a>
              <a
                      href="https://www.linkedin.com/company/exsis-digital"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
              <img className="mr-4 cursor-pointer" src={linkedinF} alt="logo exsis" />
              </a>
              <a
                      href="https://www.facebook.com/Exsisdigital/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
              <img className="mr-4 cursor-pointer" src={facebookF} alt="logo exsis" />
              </a>
              <a
                      href="https://www.instagram.com/exsisdigital/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
              <img className="mr-4 cursor-pointer imgInsta" src={redSocial} alt="logo exsis" />
              </a>
              <a
                      href="https://wa.me/+573176448607"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
              <img className="mr-4 cursor-pointer" src={whatsappF} alt="logo exsis" />
              </a>
              </div>
              <Link to="/privacy-policy" className="mt-4 block linksSectionFooter mb-8">
              {i18n.t("home.footer.textPolicy")}
              </Link>
            </div>}

             <div className="mobile:mr-0 landscape:mr-3 mobile:w-full landscape:w-auto">
             <Link to="/service-expansion">
              <p className="mb-3 landscape:block titleSectionFooter">{i18n.t("home.footer.title")}</p>
              </Link>
              <Link to="/services-software" className="block linksSectionFooter">
              {i18n.t("home.footer.textSoftware")}
              </Link>
              <Link to="/service-expansion" className="block linksSectionFooter">
                Staff Augmentation
              </Link>
              <Link to="/services-hunting" className="block linksSectionFooter">
              {i18n.t("home.footer.textHunting")}
              </Link>
              <Link to="/services-blockchain" className="block linksSectionFooter">
                  Exsis Blockchain
              </Link>
                      

            </div> 
            <div className="footer-further mobile:mr-0 landscape:mr-3 mobile:w-full landscape:w-auto">
            <Link to="/exclusive-products">
            <p className="mb-3 landscape:block titleSectionFooter">{i18n.t("home.footer.title2")}</p>
            </Link>
              <Link to="/TalentATS" className="block linksSectionFooter">
                Talent (ATS)
              </Link>
              <Link to="/agile-team" className="block linksSectionFooter">
              {i18n.t("home.footer.textAgile")}
              </Link>
              <Link to="/transformation" className="block linksSectionFooter">
              {i18n.t("home.footer.textTransformation")}
              </Link>      
            </div>
            <div className="contactUs mobile:w-full landscape:w-auto">
            <Link to="/media-blog">
            <p className="mb-3  landscape:block titleSectionFooter">X Media</p>
            </Link>
              <Link to="/media-blog" className="block linksSectionFooter">
              Blog
              </Link>
              
            {i18n.language == "es" ?
              <Link to="/x-media-podcast" className="block linksSectionFooter">
              Podcasts
              </Link>:null            
              }  
              

              <Link to="/x-media-success-stories" className="block linksSectionFooter">
              {i18n.t("home.footer.textSuccess")}
              </Link>
              
            </div>
          </div>
        </div>
        <div className="whatsapp-icon desktop:mt-72">
        <a
          href="https://wa.me/+573176448607"
          target="_blank"
          rel="noopener noreferrer"
          >
          <img
            src={WhatsAppImage}
            alt="whatsapp"
            />
        </a>  
        </div>
      </footer>
    </Fragment>
  );
};

export default Footer;