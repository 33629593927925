import React, { useContext, useEffect, useState } from "react"
import { LoadingContext } from "../../context/LoadingContext"
import { navigate } from "gatsby"
import i18n from "i18next"

const ButtonGetInTouch = () => {
  const { windowWidth } = useContext(LoadingContext)
  const [pathname, setPathname] = useState()

  useEffect(() => {
    setPathname(window.location.pathname)
  }, [])

  return (
    <>
      {windowWidth < 1025 ? (
        <button
          className="mobile:mt-4 desktop:mt-0 m-0 contentButtomGetTouch"
          style={{ alignSelf: "center" }}
          onClick={() => {
            navigate("/contact-us")
          }}
        >
          <p
            className={
              pathname == "/contact-us"
                ? "textButtomGetTouchSelected"
                : "textButtomGetTouch"
            }
          >
            {i18n.t("getInTouch.button")}
          </p>
        </button>
      ) : windowWidth > 1700 ? (
        <button
          className="mobile:mt-4 desktop:mt-0 bigdesktop:mt-8 contentButtomGetTouchResponsive mobile:mb-8"
          style={{ alignSelf: "center" }}
          onClick={() => {
            navigate("/contact-us")
          }}
        >
          <p
            className={
              pathname == "/contact-us"
                ? "textButtomGetTouchSelected"
                : "textButtomGetTouch"
            }
          >
            {i18n.t("getInTouch.button")}
          </p>
        </button>
      ) : (
        <button
          className="mobile:mt-4 desktop:mt-0 ml-32 contentButtomGetTouch"
          style={{ alignSelf: "center" }}
          onClick={() => {
            navigate("/contact-us")
          }}
        >
          <p
            className={
              pathname == "/contact-us"
                ? "textButtomGetTouchSelected"
                : "textButtomGetTouch"
            }
          >
            {i18n.t("getInTouch.button")}
          </p>
        </button>
      )}
    </>
  )
}
export default ButtonGetInTouch
