import React, { useEffect } from 'react';
 
 const TrackingScript = () => {
   useEffect(() => {
     window._nQc = "89548886";
   }, []);
 
   return (
     <>
       <script type="text/javascript">{`window._nQc = "89548886";`}</script>
       <script async src="https://serve.albacross.com/track.js" />
     </>
   );
 };
 
 export default TrackingScript;