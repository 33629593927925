import React, { useState, useEffect, useContext } from "react"
import i18n from "i18next"
import { withNamespaces } from "react-i18next"
import { LoadingContext } from "../../context/LoadingContext"

const ButtonLanguage = () => {
  const { windowWidth } = useContext(LoadingContext)
  const [language, setLanguage] = useState(i18n.language)

  function handleSelectChange(event) {
    const selectedValue = event.target.value.toLowerCase()
    setLanguage(selectedValue)
    i18n.changeLanguage(selectedValue)
  }

  return (
    <>
      {windowWidth > 1700 ? (
        <div className="cursor-pointer m-auto bottomLanguageResponsive">
          <select
            className="buttomLanguage"
            onChange={event => handleSelectChange(event)}
            value={language === "es" ? "ES" : "EN"}
          >
            <option value={"EN"}>EN</option>
            <option value={"ES"}>ES</option>
          </select>
        </div>
      ) : (
        <div className="cursor-pointer m-auto buttomLanguage2">
          <select
            className="buttomLanguage"
            onChange={event => handleSelectChange(event)}
            value={language === "es" ? "ES" : "EN"}
          >
            <option value={"EN"}>EN</option>
            <option value={"ES"}>ES</option>
          </select>
        </div>
      )}
    </>
  )
}

export default withNamespaces()(ButtonLanguage)
